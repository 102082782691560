@use "../config/" as *;
.head-to-head {
    .card {
        &-section {
            @extend %pure-white-900-bg;
            @extend %p-4;
            @extend %mb-4;
        }
        &-title {
            border-bottom: .1rem solid var(--neutral-200);
            @extend %font-18-pb;
            @extend %neutral-800;
            @extend %pb-5;
            @extend %text-center;
            @extend %uppercase;
        }
        &-head {
            @extend %flex-sb-n;
            @extend %p-4;
        }
        &-body {
            @extend %py-4;
        }
        &-item {
            border-top: .1rem solid var(--neutral-200);
            @extend %flex-sb-c;
            @extend %p-6-5;
            .number:last-child {
                @extend %text-right;
            }
        }
        &-wrap {
            @extend %w-50;
            &.team-a {
                @extend %pr-2;
            }
            &.team-b {
                @extend %pl-2;
            }
        }
    }
    .player {
        &-thumbnail {
            width: 7.2rem;
            aspect-ratio: 1/1;
            @extend %flex;
            @extend %mb-4;
            .image {
                @extend %secondary-50-bg;
                @extend %rounded-radius;
            }
        }
        &-name {
            @extend %flex-column;
            @extend %mb-3;
            @extend %uppercase;
            @extend %primary-1000;
        }
    }
    .fname {
        word-wrap: break-word;
        @extend %font-12-pb;
    }
    .lname {
        word-wrap: break-word;
        @extend %font-16-pb;
    }
    .team {
        &-name {
            word-wrap: break-word;
            @extend %font-10-pb;
            @extend %neutral-500;
            @extend %uppercase;
        }
        &-image {
            width: 3.6rem;
            height: 2.2rem
        }
        &-b {
            .player-data {
                @extend %text-right;
            }
            .team-image,
            .player-thumbnail {
                @extend %ml-auto;
                @extend %d-block;
            }
        }
    }
    .number {
        flex: 1;
        @extend %font-16-pb;
        @extend %neutral-800;
    }
    .label {
        @extend %text-center;
        @extend %font-12-pb;
        @extend %neutral-500;
        @extend %uppercase;
    }
    .section-team {
        .player-thumbnail {
            width: unset;
            aspect-ratio: unset;
            .image {
                width: 7.1rem;
                height: 4.4rem;
                border-radius: var(--space-1);
                @extend %half-radius;
            }
        }
        .team {
            &-name {
                @extend %font-16-pb;
                @extend %neutral-800;
            }
            &-b {
                .image {
                    @extend %ml-auto;
                    @extend %d-block;
                }
            }
        }
    }
    .section-head-redirect {
        .card {
            &-title {
                border-bottom: unset;
                word-wrap: break-word;
            }
            &-content {
                .card-title {
                    border-bottom: unset;
                    @extend %text-left;
                    @extend %font-10-pb;
                    @extend %neutral-800;
                    @extend %pb-0;
                }
            }
            &-item {
                justify-content: unset;
                @extend %px-0;
                @extend %py-4;
            }
            &-thumbnail {
                @extend %pr-2;
            }
            &-action {
                @extend %pl-2;
                @extend %ml-auto;
                @extend %flex-v-center;
            }
        }
        .text {
            line-height: 3;
            @extend %d-block;
            @extend %ml-auto;
            @extend %font-10-pm;
            @extend %neutral-800;
        }
        .btn-text {
            @extend %font-0;
            &::after {
                @extend %pl-2;
                @include icon(p-arror-right, 12);
            }
        }
        .image {
            width: 2.6rem;
            height: 1.6rem;
        }
    }
}
@include mq(col-md) {
    .head-to-head {
        .player-thumbnail {
            width: 9.1rem;
        }
        .team-b {
            .player-thumbnail {
                margin-left: 0;
            }
        }
        .card {
            &-wrap {
                gap: 1.4rem;
                @include flex-config(flex, null, null, center);
            }
            &-wrap.team-b {
                flex-direction: row-reverse;
            }
            &-item {
                padding-block: var(--space-7);
            }
            &-title {
                font-size: 2rem;
            }
        }
        .number {
            font-size: 1.8rem;
        }
        .section-team {
            .team-name {
                font-size: 2rem;
            }
            .player-thumbnail {
                margin-bottom: 0;
                .image {
                    width: 8.2rem;
                    height: 5rem;
                }
            }
            .team-a {
                padding-left: var(--space-8);
            }
            .team-b {
                padding-right: var(--space-8);
            }
        }
        .section-head-redirect {
            .card-content {
                .card-title {
                    font-size: 1.2rem;
                    text-align: left;
                }
            }
            .text {
                font-size: 1.2rem;
            }
            .image {
                width: 4.6rem;
                height: 3rem;
            }
        }
    }
}