@use "../config/" as *;
.waf-live-blog {
    .detail-container {
        border-top: .1rem solid var(--neutral-100);
        @extend %pt-5;
    }
    .detail-section {
        @extend %half-radius;
        @extend %p-4;
        @extend %neutral-50-bg;
    }
    .detail-section-time {
        width: max-content;
        @extend %py-5;
        @extend %relative;
        &:first-child::before {
            top: 50%;
            height: 50%;
        }
        &:before {
            content: '';
            border-right: .15rem dashed var(--neutral-500);
            @extend %h-100;
            @include position(0, null, null, var(--space-8));
        }
        .text {
            width: max-content;
            height: 3rem;
            @extend %gap-2;
            @extend %relative;
            @extend %half-radius;
            @extend %font-12-pr;
            @extend %neutral-800;
            @extend %primary-300-bg;
            @extend %py-0;
            @extend %px-3;
            @extend %flex-c-c;
            &:before {
                @extend %secondary-800;
                @include icon(timer);
            }
        }
    }
    .conclusion-section {
        a.detail-title {
            &::before {
                font-weight: 100;
                @include icon(link-redirect, 14);
                @extend %mr-1;
            }
        }
    }
    .facebook-card {
        max-width: 50rem;
        margin: auto
    }
    .poll-section {
        .question {
            border-bottom: .1rem solid var(--neutral-100);
            @extend %pb-4;
            @extend %mb-4;
            @extend %neutral-800;
            @extend %font-14-pb;
        }
        .button-group {
            flex-wrap: wrap;
            @extend %gap-2;
            @extend %flex;
            .btn-item {
                height: 3.5rem;
                border-radius: .3rem;
                border: .1rem solid var(--neutral-100);
                cursor: pointer;
                @extend %w-100;
                @extend %px-3;
                @extend %flex-sb-c;
                &.active {
                    @extend %secondary-600-bg;
                    .btn-label,
                    .btn-value {
                        @extend %pure-white-900;
                    }
                }
            }
            .btn-label,
            .btn-value {
                @extend %neutral-600;
                @extend %uppercase;
                @extend %font-12-pr;
                &.btn-value {
                    font-weight: 700;
                }
            }
        }
    }
}
.waf-detail {
    &.waf-live-blog {
        .article-head {
            .title {
                font-size: 2.4rem;
                margin-bottom: 0;
            }
        }
        .text,
        p,
        li {
            @extend %neutral-600;
        }
        .detail-section {
            .detail-title {
                @extend %neutral-800;
                @extend %font-16-pb
            }
            p, .text {
                @extend %font-14-pr;
                @extend %pb-5;
            }
        }
        .waf-quote {
            .text {
                color: var(--neutral-900);
            }
        }
        .waf-cricketscorecard {
            .text {
                color: var(--pure-white-900);
            }
            .card-head-top {
                .match-info {
                    .text {
                        color: var(--neutral-800);
                        font-size: 1.2rem;
                        font-weight: 700;
                    }
                }
            }
            .score-data {
                .over-item {
                    .text {
                        color: var(--neutral-600);
                    }
                    &.four {
                        .text {
                            @extend %pure-white-900;
                        }
                    }
                    &.six {
                        .text {
                            @extend %pure-white-900;
                        }
                    }
                    &.wicket {
                        .text {
                            @extend %pure-white-900;
                        }
                    }
                }
            }
            .team-divider {
                .text-vs {
                    color: var(--pure-white-900);
                    font-weight: 700;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-live-blog {
        .detail-section-time {
            padding-block: var(--space-6);
            .text {
                font-size: 1.4rem;
            }
        }
        .poll-section {
            .question {
                font-size: 2rem;
            }
            .button-group {
                gap: var(--space-5);
                .btn-item {
                    width: calc(50% - 1rem);
                }
            }
        }
    }
    .waf-detail {
        &.waf-live-blog {
            .article-head {
                .title {
                    font-size: 3.6rem;
                }
            }
            .detail-section {
                .detail-title {
                    font-size: 2rem
                }
                p,
                .text {
                    font-size: 1.6rem;
                }
            }
            .waf-cricketscorecard {
                .card-head-top {
                    .match-info {
                        .text {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
}