@use "./config/" as *;
@forward "./../new-matchcenter/head-to-head";
@forward "./../partials/form-common";
.waf-statsdetail {
    @extend %p-4;
    .waf-head {
        @extend %d-none;
    }
    .bio {
        @extend %mb-4;
        .title {
            @extend %uppercase;
            @extend %secondary-800;
            @extend %font-16-pb;
            @extend %mb-4;
        }
        p,
        strong {
            line-height: 1.4;
            @extend %primary-50-bg;
            @extend %neutral-900;
            @extend %font-14-pr;
            @extend %p-4;
            @extend %half-radius;
            strong {
                font-weight: 700;
                padding: var(--space-0);
                @extend %transparent-bg;
            }
        }
    }
    .player-stats {
        .card {
            &-item {
                background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
                @extend %half-radius;
                @extend %mb-4;
            }
            &-body {
                @extend %pure-white-900-bg-1;
                .card-points {
                    border-bottom: .1rem solid clr(pure-white-900, 1);
                    @extend %px-1;
                    @extend %relative;
                    &:not(:last-child):nth-child(odd)::after {
                        content: '';
                        width: .1rem;
                        height: 50%;
                        right: 0;
                        @extend %pure-white-900-bg-1;
                        @extend %pos-y-center;
                    }
                    &-list {
                        align-items: stretch;
                    }
                    .label {
                        @extend %text-center;
                    }
                }
            }
            &-head {
                @extend %flex-n-c;
                @extend %flex-wrap;
                @extend %p-4;
                @extend %gap-4;
                .card-points {
                    @extend %pure-white-900-bg-1;
                    @extend %half-radius;
                    &-list {
                        @extend %d-none;
                        @extend %ml-auto;
                        @extend %py-0;
                    }
                    .number {
                        @extend %font-24-pb;
                        @extend %primary-300;
                    }
                    .label {
                        @extend %font-14-pr;
                        @extend %primary-300;
                        @extend %capitalize;
                    }
                }
            }
            &-thumbnail {
                width: 7.9rem;
                height: 7.9rem;
                aspect-ratio: 1;
                border: .2rem solid var(--primary-300);
                @extend %circle-radius;
                @extend %hidden;
                @extend %neutral-300-bg;
                .player-image {
                    object-fit: cover;
                    object-position: top;
                    @extend %h-100;
                }
            }
            &-position {
                @extend %font-20-pb;
                @extend %pure-white-900-9;
                @extend %mb-2;
            }
            &-name {
                max-width: 20rem;
                @extend %font-20-pb;
                @extend %flex;
                @extend %flex-wrap;
                @extend %gap-1;
                .name {
                    @extend %pure-white-900;
                    @extend %capitalize;
                }
            }
            &-points-list {
                // overflow-x: auto;
                @extend %flex-c-c;
                @extend %flex-wrap;
                &:not(:first-child) {
                    .team-image {
                        @extend %d-none;
                    }
                }
            }
            &-points {
                // flex: 1;
                @extend %flex-column-c-c;
                @extend %gap-2;
                @extend %w-50;
                @extend %py-4;
                .number {
                    @extend %pure-white-900;
                    @extend %font-16-pb;
                    @extend %text-center;
                }
                .label {
                    @extend %pure-white-900-6;
                    @extend %uppercase;
                    @extend %font-10-pr;
                }
            }
        }
        &.country-flag {
            .card-thumbnail {
                height: auto;
                aspect-ratio: 3 / 2;
                border: 0.1rem solid clr(pure-white-900, 3);
                @extend %half-radius;
                .player-image {
                    object-position: center;
                }
            }
        }
    }
    .table {
        overflow-x: auto;
        border: .1rem solid clr(neutral-100, 2);
        .text {
            @extend %neutral-800;
            @extend %font-10-pm;
        }
        &-responsive {
            .btn-more {
                padding-inline: var(--space-4);
                @include btn-animation();
                height: 3.4rem;
                @extend %mx-auto;
                @extend %my-4;
                &::before {
                    @extend %d-none;
                }
                &::after {
                    font-weight: 400;
                }
                .btn-text {
                    @extend %font-12-pb;
                    @extend %uppercase;
                }
            }
        }
        &-head {
            @extend %neutral-1000-bg;
            .text {
                @extend %font-10-pb;
                @extend %uppercase;
            }
            .table-data {
                @extend %neutral-1000-bg;
            }
        }
        &-row {
            border-bottom: 0;
        }
        &-body {
            .table-row {
                &:not(:last-child) {
                    .table-data {
                        border-bottom: .1rem solid clr(neutral-100, 2);
                    }
                }
            }
            .table-data {
                --_table-data-height: 6.5rem;
                &.highlight {
                    @extend %primary-50-bg;
                    .text {
                        @extend %font-10-pb;
                    }
                }
            }
        }
        &-left {
            width: unset;
            .table-body {
                .text {
                    @extend %font-12-pb;
                }
            }
        }
        &-right {
            width: unset;
            overflow: unset;
        }
        &-data {
            min-width: 10rem;
            @extend %flex-c-c;
            @extend %px-2;
            &.table-data-player {
                justify-content: flex-start;
                min-width: 13rem;
            }
            &.table-data-team-info {
                min-width: 12rem;
            }
            &.table-data-venue-name {
                min-width: 15rem;
            }
            &.table-data-venue {
                min-width: 15rem;
            }
            &.table-data-vs-team-name {
                min-width: 13rem;
            }
            &-player-info {
                justify-content: flex-start;
                @extend %px-1;
            }
        }
        &-data-position {
            min-width: 4.2rem;
            @extend %font-12-pb;
        }
        &-data-player {
            @extend %pl-2;
            .player-image {
                @extend %d-none;
            }
            .table-data-name,
            .team-data-team {
                @extend %neutral-900;
                @extend %font-12-pb;
                @extend %text-left;
            }
            .table-data-text {
                line-height: 1;
                @extend %text-left;
            }
        }
        .no-data-wrap {
            border: .1rem solid var(--secondary-900);
            @extend %p-3;
            @extend %text-center;
            @extend %uppercase;
            @extend %full-radius;
            @extend %secondary-900;
            @extend %font-16-pb;
        }
    }
    .head-to-head {
        .card {
            &-section {
                border-top: .1rem solid var(--neutral-200);
                @extend %transparent-bg;
            }
            &-head {
                padding: var(--space-0);
                .team-versus {
                    @extend %font-16-pb;
                    @extend %neutral-800;
                    @extend %flex-c-c;
                }
            }
            &-title {
                @extend %d-none;
            }
            &-wrap {
                &.team-b {
                    .player-data {
                        @extend %ml-auto;
                        @extend %text-center;
                    }
                }
            }
        }
        .custom-form {
            .form-input-drop {
                height: 3.6rem;
            }
            .form-group {
                margin-bottom: var(--space-0);
            }
            .form-dropdown {
                top: 3.6rem;
            }
            .btn {
                &-text {
                    @extend %font-12-pm;
                }
                &-section {
                    @extend %w-100;
                }
                &-go {
                    @extend %flex-c-c;
                    @extend %p-3;
                    @extend %primary-300-bg;
                    @extend %half-radius;
                    @extend %w-100;
                    .btn-text {
                        @extend %font-12-pb;
                        @extend %neutral-800;
                        @extend %uppercase;
                    }
                }
            }
        }
        .versus {
            line-height: 1;
            @extend %my-4;
            @extend %text-center;
            @extend %font-12-pb;
            @extend %d-block;
        }
        .player {
            &-data {
                width: 7.1rem;
                @extend %text-center;
                .full-name {
                    @extend %d-none;
                }
            }
        }
    }
    .accordion {
        &-wrapper {
            @extend %my-4;
            .btn-headtohead {
                @extend %flex-sb-c;
                @extend %p-3;
                @extend %secondary-50-bg;
                @extend %half-radius;
                @extend %w-100;
                @extend %mt-2;
                @extend %mb-4;
                .btn-text {
                    @extend %font-14-pb;
                    @extend %neutral-800;
                    @extend %capitalize;
                }
            }
        }
        &-header {
            min-height: 4rem;
            @extend %flex-sb-c;
            @extend %py-2;
            @extend %px-3;
            @extend %w-100;
            @extend %half-radius;
            @extend %secondary-50-bg;
            &::after {
                transition: .3s all ease;
                @include icon(p-arrow-down);
                @extend %neutral-800;
            }
            &[aria-expanded="true"] {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                @extend %primary-300-bg;
                &::after {
                    transform: rotate(180deg);
                }
                ~ .accordion-body {
                    grid-template-rows: 1fr;
                }
            }
            .btn-text {
                line-height: 2rem;
                @extend %text-left;
                @extend %font-14-pb;
                // @extend %capitalize;
                @extend %neutral-800;
            }
        }
        &-body {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 0.5s ease-out;
            .stats-link {
                @extend %text-left;
                &-item {
                    line-height: 1;
                    border-bottom: .2rem solid transparent;
                    @extend %p-3;
                    &.active {
                        border-bottom: .2rem solid var(--primary-300);
                    }
                }
                &-text {
                    @extend %font-12-pm;
                    @extend %neutral-900;
                }
                &-list {
                    list-style: none;
                    @extend %p-0;
                }
            }
            .stats-link-list,
            .custom-form {
                overflow: hidden;
                border-radius: 0 0 var(--half-radius) var(--half-radius);
                @extend %pure-white-900-bg;
            }
        }
        &-item {
            @extend %mb-4;
        }
    }
    .form {
        &-wrapper {
            @extend %w-100;
            @extend %gap-2;
            @extend %flex-column-c-c;
            @extend %px-0;
            @extend %pb-4;
            .btn {
                @extend %flex-c-c;
                @extend %p-3;
                @extend %primary-300-bg;
                @extend %half-radius;
                @extend %w-100;
                &-section {
                    @extend %w-100;
                }
                &-text {
                    @extend %font-12-pb;
                    @extend %neutral-800;
                    @extend %uppercase;
                }
            }
            .versus {
                @extend %uppercase;
                @extend %font-12-pb;
                @extend %neutral-900;
            }
        }
        &-group {
            @extend %w-100;
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
        &-label {
            @extend %d-none;
        }
        &-dropdown {
            top: 4.7rem;
        }
        &-input-drop {
            @extend %py-5;
            &::after {
                color: var(--neutral-800);
            }
            &[aria-expanded="true"] {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
            }
            .btn-text {
                @extend %font-12-pm;
                @extend %neutral-900;
            }
        }
    }
    .filter {
        &-tab-wrap {
            @extend %relative;
        }
        &-actions {
            @include position(-1.2rem, -1.6rem, null, null, absolute);
        }
        &-section {
            .btn-more {
                height: 3.8rem;
                width: 9.3rem;
            }
        }
        &-text {
            @extend %uppercase;
            @extend %font-12-pm;
            @extend %neutral-600;
        }
    }
    .waf-select-box {
        .sub-title,
        .title {
            @extend %font-14-pb;
            @extend %uppercase;
            @extend %neutral-800;
        }
        .list-item {
            padding-inline: 0;
            &-text {
                line-height: 2rem;
                @extend %font-14-pm;
                @extend %neutral-600;
            }
            &.active {
                .list-item-text {
                    font-weight: 700;
                }
            }
        }
    }
    .tabs {
        max-width: 24rem;
        @extend %py-1;
        @extend %px-2;
        @extend %secondary-1000-bg;
    }
    .tab {
        &-container-head {
            @extend %my-6;
            .title {
                @extend %font-16-pb;
                @extend %capitalize;
                @extend %neutral-800;
            }
        }
        &-name {
            height: 3rem;
            @extend %transparent-bg;
            .btn-text {
                @extend %pure-white-900;
                @extend %font-12-pb;
                @extend %uppercase;
            }
            &.active {
                border-radius: var(--half-radius);
                @extend %primary-300-bg;
                .btn-text {
                    @extend %neutral-800;
                }
            }
        }
    }
    .stats-wrapper .bio {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .waf-statsdetail {
        .form-wrapper {
            .btn {
                padding: var(--space-4);
            }
        }
        .filter-section .more-filters {
            border-top: none;
        }
    }
}
@include mq(col-lg) {
    .waf-statsdetail {
        .waf-body {
            position: relative;
            display: flex;
            gap: var(--space-4);
        }
        .bio,
        .tab-container {
            // width: calc(100% - 31.6rem);
            // margin-left: 31.6rem;
            background-color: var(--pure-white-900);
        }
        .bio {
            margin-bottom: 0;
            padding: var(--space-4) var(--space-4) 0;
            border-radius: var(--half-radius) var(--half-radius) 0 0;
        }
        .tab-container {
            padding: var(--space-4);
            border-radius: 0 0 var(--half-radius) var(--half-radius);
        }
        .accordion {
            &-group {
                width: 100%;
            }
            &-wrapper {
                margin-block: var(--space-0);
            }
        }
        .form {
            &-wrapper {
                flex-direction: row;
                padding: var(--space-2) var(--space-0) var(--space-6);
                .versus {
                    margin-block: var(--space-0);
                    margin-inline: var(--space-11);
                }
            }
            &-group {
                width: 100%;
            }
        }
        .table {
            overflow-x: unset;
            &-responsive {
                overflow-x: unset;
                .btn-more {
                    &::before {
                        display: flex;
                    }
                }
            }
            &-data-position {
                width: 4rem;
            }
            &-left {
                width: 25%;
            }
            &-right {
                width: 75%;
            }
            &-data {
                min-width: 10rem;
            }
            &-head {
                .table-data {
                    min-height: 4rem;
                }
            }
        }
        .head-to-head {
            .card {
                &-head {
                    padding-block: var(--space-6);
                }
                &-body {
                    padding-block: var(--space-0);
                }
                &-section {
                    padding: var(--space-0);
                    border: .1rem solid var(--neutral-1000);
                }
                &-item {
                    padding-inline: var(--space-19);
                }
            }
            .short-name {
                display: none;
            }
            .player-data {
                width: max-content;
                .full-name {
                    display: flex;
                    font-size: 1.6rem;
                }
            }
            .section-team {
                .team {
                    &-a {
                        padding-left: var(--space-5);
                    }
                    &-b {
                        padding-right: var(--space-5);
                    }
                }
            }
        }
        .player-stats {
            &.country-flag {
                .card-thumbnail {
                    border: .15rem solid clr(pure-white-900, 3);
                    border-radius: 0.6rem;
                }
            }
            .card {
                &-head {
                    .card {
                        &-points-list {
                            display: block;
                            padding: var(--space-0);
                        }
                        &-points {
                            padding: var(--space-3);
                            width: auto;
                        }
                    }
                }
                &-name {
                    max-width: unset;
                    font-size: 3.2rem;
                }
                &-body {
                    .card-points {
                        min-width: unset;
                        flex: 1;
                        border-bottom: none;
                        &:not(:last-child):nth-child(even)::after {
                            content: '';
                            width: .1rem;
                            height: 50%;
                            background-color: clr(pure-white-900, 1);
                            @include position-combo(y-center, 0);
                        }
                    }
                }
                &-info {
                    gap: var(--space-4);
                    @include flex-config(flex, row, null, center);
                }
                &-position {
                    margin-bottom: var(--space-0);
                    font-size: 2.4rem;
                }
            }
        }
        .tabs {
            width: 100%;
            max-width: unset;
            padding: var(--space-2);
        }
        .waf-select-box {
            height: auto;
            .selected-title::after {
                display: none;
            }
            .list-item {
                padding-block: var(--space-3);
            }
        }
        .select-list .list-item-text {
            color: clr(neutral-900);
            font-size: 1.2rem;
        }
        .filter {
            &-actions {
                top: 1rem;
                right: 1.6rem;
            }
            &-tab-wrap {
                // width: 30rem;
                height: auto;
                gap: var(--space-4);
                background-color: var(--pure-white-900);
                padding: var(--space-4);
                border-radius: var(--half-radius);
                @include flex-config(flex, column);
                // @include position-combo(tl);
            }
            &-section {
                background: transparent;
                .btn-more {
                    flex-direction: row-reverse;
                    width: 5.9rem;
                    height: 4.6rem;
                    background: clr(neutral-50);
                    gap: var(--space-1);
                    &::before {
                        color: clr(neutral-600);
                        rotate: 0deg;
                        transition: 0.3s linear rotate;
                        @include icon(p-arrow-down, 9);
                    }
                    .btn-text {
                        color: clr(neutral-600);
                    }
                }
                .btn-text {
                    font-size: 0;
                    &::before {
                        content: "MORE";
                        font-size: 1rem;
                    }
                }
                .filter-wrap {
                    padding-right: var(--space-16);
                    gap: 0;
                }
                .more-filters {
                    overflow: visible;
                    padding: 0;
                }
                &.active {
                    .more-filters {
                        height: 0;
                        padding: 0;
                    }
                    .btn-more::before {
                        color: clr(neutral-800);
                        rotate: 180deg;
                    }
                    .btn-text::before {
                        content: "MORE";
                        font-weight: 700;
                        color: clr(neutral-800);
                        font-size: 1rem;
                    }
                    .select-box-wrap {
                        max-height: 44rem;
                        overflow-y: auto;
                        padding-block: var(--space-2);
                    }
                }
                .select-box-wrap {
                    background: clr(pure-white-900);
                    max-width: 36rem;
                    right: 0;
                    left: unset;
                    box-shadow: 0 .4rem 1rem 0 clr(neutral-900, 5);
                    padding: var(--space-0) var(--space-3);
                    display: block;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height .4s ease-in, padding .4s ease-in;
                }
            }
            &-wrap {
                height: 4.6rem;
                background: clr(pure-white-900);
                border-radius: var(--half-radius) 0 0 var(--half-radius);
                border: .1rem solid var(--neutral-50);
            }
            &-btn {
                flex: 1;
                min-width: 5.2rem;
                position: relative;
                padding-inline: var(--space-1);
                &.active {
                    background: clr(primary-300);
                    border-radius: var(--half-radius);
                    .filter-text {
                        color: clr(neutral-800);
                        font-weight: 700;
                    }
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    content: "";
                    background: clr(neutral-1000);
                    width: .1rem;
                    height: 1.4rem;
                    z-index: var(--z-default);
                    @include position-combo(y-center, -0.1rem);
                }
                .filter-text {
                    font-size: 1rem;
                }
            }
            &-wrapper {
                width: 30rem;
                .bio {
                    display: none;
                }
            }
        }
        .stats-wrapper {
            width: calc(100% - 31.6rem);
            .bio {
                gap: var(--space-4);
                @include flex-config(flex, column);
                .title {
                    margin-bottom: 0;
                }
            }
        }
    }
}